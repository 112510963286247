$env: production;
@import '@/styles/_component-base.styles.scss';

.heroImage {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 67%;

    @include breakpoint(lg) {
        background-position: center center;
    }

    @include responsive-dpi-imgix('/homepage/hero', 'hero-v1', 375, 768, 1440);
}